.onPage{
  text-decoration: underline
}

@media screen and (max-width: 499px) {

  .btns {
    
    width: 150px;
    background-color: #facc15;
    color: rgb(0, 0, 0);
    padding: 5px;
    font-weight: 500;
    margin-bottom: 5%;
    transition: transform .3s;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;

  }

  .btnsDis {
    width: 150px;
    background-color: #77946c;
    border: none;
    color: rgb(255, 255, 255);
    padding: 5px;
    font-weight: 500;
    margin-bottom: 5%;
    transition: transform .3s;
    cursor: no-drop;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  }

  .btns2 {
    width: 150px;
    color: rgb(0, 0, 0);
    padding: 5px;
    font-weight: 500;
    transition: transform .3s;
    background-color: rgb(238, 62, 62);
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    border: none;
  }

  .btns2Dis {
    width: 150px;
    background-color: rgb(196, 134, 134);
    cursor: no-drop;
    color: rgb(255, 255, 255);
    padding: 5px;
    font-weight: 500;
    transition: transform .3s;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  }

  .btns:hover {
    transform: scale(1.1);
  }

  .btns2:hover {
    transform: scale(1.1);
  }

  .btnsMain {
    display: flex;
    flex-flow: column nowrap;
    margin-top: auto;
    margin-bottom: auto;
    display: none;
  }

  .btnsMainBig {
    display: flex;
    flex-flow: column nowrap;
    margin-top: auto;
    margin-bottom: auto;
  }

  .markBar {
    background-color: #000000;
    margin-top: 2%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    background-color: #00000000;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
    padding: 15px;
    
  }

  .markBarCon {
    background-color: #222222a6;
    display: flex;
    flex-flow: row nowrap;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding: 5px;
    margin-top: 20px;
    margin-bottom: 20px;
    border: 2px solid #a87026;
    box-shadow: 0 0 10px #d6a25e;
  }

  .markBar2 {
    flex-flow: column nowrap;
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: -10%;
  }

  .num {
    font-size: 16px;
    color: #ffffff;

    text-align: center;
  }

  .name {
    color: #ffffff;
    font-size: 16px;
    text-align: center;
    font-family: 'Russo One', sans-serif;
  }

  .num2 {
    font-size: 16px;
    color: white;
    margin-top: 15px;
    text-align: center;
  }

  .name2 {
    color: white;
    font-size: 16px;
    text-align: center;
    font-weight: 500;
  }

  .markBar img {
    width: 70px;
    height: 70px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 20%;
  }

  .markBarMain {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-top: 3%;
    margin-bottom: 5%;
  }

  .stakeMain {
    display: flex;
    flex-flow: column nowrap;
    background-color: black;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.49343487394957986) 0%, rgba(0, 0, 0, 0.804359243697479) 69%, rgba(0, 0, 0, 0.896796218487395) 98%), url('./assets/fire.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    
  }

  .stakeMain2 {
    border-right: 20px #f3ba2f solid;
    border-top: #f3ba2f 20px solid;
  }

  .sticky {
    width: 20%;

    bottom: 0;
    right: 90%;
    margin-top: -10%;
  }

  .items {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }

  .board {
    margin-bottom: 5%;
    padding: 35px;
    border: #ffffff 8px double;
    margin-left: 1%;
    margin-right: 1%;
    background: rgba(46, 46, 46, 0.2);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    width: 90%;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }

  .boardMain {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    
    margin-bottom: 7%;

  }

  .nameStake {
    width: 50%;
  }

  .carrots {
    width: 90px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .egg {
    width: 70px;
    height: 70px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .egg2 {
    width: 95px;
    height: 95px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .boardImg {
    background-color: #ffb963;

  }

  .boardPics {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    margin-bottom: 8%;
  }

  .approveBtns {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    margin-top: 2%;
  }

  .approveBtns1 {
    border: 4px solid;
    border-image-slice: 1;
    border-image-source: linear-gradient(to left, #facc15, #f0d56b);
    background-color: rgba(206, 8, 8, 0);
    box-shadow: 0 0 5px gold;
    color: rgb(255, 255, 255);
    padding: 15px;
    font-size: 20px;
    font-family: 'Russo One', sans-serif;
    width: 270px;
    transition: transform .3s;
  }

  .approveBtns1:hover {
    transform: scale(1.1);
  }

  .approveBtns2:hover {
    transform: scale(1.1);
  }

  .approveBtns3:hover {
    transform: scale(1.1);
  }

  .approveBtns3 {
    border: 4px solid;
    border-image-slice: 1;
    border-image-source: linear-gradient(to left, rgb(231, 80, 80), rgb(206, 8, 8));
    background-color: rgba(206, 8, 8, 0);
    box-shadow: 0 0 5px red;
    color: white;
    color: white;
    padding: 15px;
    font-size: 23px;
    font-family: 'Russo One', sans-serif;
    width: 270px;
    transition: transform .3s;
  }

  .approveBtns3Dis {
    background-color: rgb(224, 111, 111);
    border: none;
    cursor: no-drop;
    color: rgb(187, 183, 183);

    padding: 20px;
    font-size: 23px;
    font-family: 'Russo One', sans-serif;
    width: 400px;
    transition: transform .3s;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  }

  .approveBtns2 {
    background: none;
    color: white;
    padding: 20px;
    border-radius: 10px;
    font-size: 23px;
    border: 4px #70512b solid;

    width: 200px;
    transition: transform .3s;
  }


  .wallet3 {
    
    background-color: black;
    border: solid 3px;
    color: rgb(255, 255, 255);
    padding: 15px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 18px;
    margin-left: auto;
    margin-right: auto;
    width: 250px;
    transition: transform .5s;
    z-index: 1;
    margin-bottom: 10%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;

  }

  .lookDown {
    width: 20px;
    height: 20px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }


  .wallet3:hover {
    cursor: pointer;
    cursor: pointer;
  }

  .wallet3::after {
    transition: all 0.8s
  }

  .btn3 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .link {
    text-align: center;
    font-size: 15px;
    margin-top: 5px;
  }

  .images_show {
    width: 90%;
  }

  .hide {
    display: none;
  }

  .stake {
    display: block;
    background-color: red;
    color: white;
    border-radius: 10px;
    border: none;
    width: fit-content;
    padding: 5px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    cursor: pointer;
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
  }

  .stake2 {
    display: block;
    background-color: red;
    color: white;
    border-radius: 10px;
    border: none;
    width: 85px;
    padding: 5px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    cursor: pointer;
    margin-top: 10px;
    text-align: center;
  }

  .stake3 {
    display: block;
    background-color: red;
    color: white;
    border-radius: 10px;
    border: none;
    width: 85px;
    padding: 5px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    cursor: pointer;
    margin-bottom: 10px;
    text-align: center;
  }

  .stake:hover {}

  .hide:hover {}

  .tokenIds {
    display: flex;
    justify-content: center;
    color: white;
    flex-flow: row wrap;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    height: max-content;
    margin-bottom: 10%;
  }

  .tokenIds2 {
    display: flex;
    justify-content: center;
    color: white;
    flex-flow: row wrap;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 55%;
  }

  .tS {
    color: #3a9ae8;
    text-align: center;
    margin-top: 2%;
    font-size: 27px;
    
  }

  .unstakeImg {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;

  }

  .imagesAndBtn {
    justify-content: center;
  }

  .flip-card {
    background-color: transparent;
    width: 150px;
    perspective: 1000px;
    background-color: #e0706c;
    height: fit-content;

    margin-top: 10%;
  }

  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }

  .flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
  }

  .flip-card-front,
  .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  .btnCenter {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .flip-card-front {
    /* background-color: #bbb;*/
    color: black;
  }

  .flip-card-back {
    /*background-color: #2980b9;*/
    color: white;
    transform: rotateY(180deg);
  }

  .loginFormNote {
    height: 90%;
    width: 90%;
    background-color: rgba(255, 255, 255, 0.13);
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    border-radius: 10px;
    backdrop-filter: blur(10px);
    border: 2px solid rgba(255, 255, 255, 0.1);
    box-shadow: 0 0 40px rgba(8, 7, 16, 0.6);
    padding: 50px 35px;
  }

  .formMain2 {
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.856);
    position: fixed;
    width: 50%;
    height: 80vh;
    top: 50%;
    left: 50%;
    z-index: 1;
    overflow: scroll;
    overflow-x: hidden;
    border-radius: 10px;
    z-index: 100000;
    border: white 3px solid;
    padding: 10px;
  }

  .formMain {
    z-index: 10000000000000000;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.856);
    position: fixed;
    width: 80%;
    height: 80vh;
    top: 50%;
    left: 50%;
    z-index: 1;
    overflow: scroll;
    overflow-x: hidden;
    border-radius: 10px;
    z-index: 100000;
    border: white 3px solid;

  }

  .formMain {
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.856);
    position: fixed;
    width: 80%;
    height: 80vh;
    top: 50%;
    left: 50%;
    z-index: 1;
    overflow: scroll;
    overflow-x: hidden;
    border-radius: 10px;
    z-index: 100000;
    border: white 3px solid;

  }

  .formMain1 {
    transform: translate(-50%, -50%);
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 50%;
    left: 50%;
    z-index: 100000;

    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
  }

  .ownNFT {
    margin-bottom: 55%;
  }

  .elementName {
    font-weight: 500;
    color: red;
    font-size: 20px;
    text-align: center;
  }

  .elementName2 {
    font-weight: 500;
    color: rgb(255, 255, 255);
    font-size: 20px;
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .elementName3 {
    font-weight: 500;
    color: red;
    font-size: 20px;
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .medalDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .closeNote {
    position: absolute;
    right: 0;
    top: 1%;
    color: rgba(255, 255, 255, 0.836);
    font-size: 30px;

    background-color: rgba(172, 67, 67, 0);
    width: 100px;
    height: fit-content;
    cursor: pointer;
    border: none;
  }

  .closeNote:hover,
  .closeNote:focus {
    color: rgb(71, 70, 70);
  }

  .rewards {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;

  }

  .discordB {
    display: none;
  }

  .medal {
    width: 35px;
    height: 35px;
  }

  .stakebtn:hover {
    transform: scale(1.1);
  }

  .stakebtn2:hover {
    transform: scale(1.1);
  }

  .stakebtnDiv {
    
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .rabbitImg {
    width: 80px;
    margin-top: 20%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 2%;
  }

  .rabbitImgLogo {
    width: 80px;
    margin-top: 20%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    border-radius: 100px;
    margin-bottom: 2%;
    border: 2px solid goldenrod;
    box-shadow: 0 0 10px gold;
  }

  .rabbitImg2 {
    width: 80px;
    margin-top: 20%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .stakeT {
    color: #3a9ae8;
    font-size: 45px;
  }
}

@media screen and (min-width: 500px) and (max-width: 767px) {

  .btns {
    
    width: 150px;
    background-color: #facc15;
    color: rgb(0, 0, 0);
    padding: 5px;
    font-weight: 500;
    margin-bottom: 5%;
    transition: transform .3s;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;

  }

  .btnsDis {
    width: 150px;
    background-color: #77946c;
    border: none;
    color: rgb(255, 255, 255);
    padding: 5px;
    font-weight: 500;
    margin-bottom: 5%;
    transition: transform .3s;
    cursor: no-drop;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  }

  .btns2 {
    width: 150px;
    color: rgb(0, 0, 0);
    padding: 5px;
    font-weight: 500;
    transition: transform .3s;
    background-color: rgb(238, 62, 62);
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    border: none;
  }

  .btns2Dis {
    width: 150px;
    background-color: rgb(196, 134, 134);
    cursor: no-drop;
    color: rgb(255, 255, 255);
    padding: 5px;
    font-weight: 500;
    transition: transform .3s;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  }

  .btns:hover {
    transform: scale(1.1);
  }

  .btns2:hover {
    transform: scale(1.1);
  }

  .btnsMain {
    display: flex;
    flex-flow: column nowrap;
    margin-top: auto;
    margin-bottom: auto;
    display: none;
  }

  .btnsMainBig {
    display: flex;
    flex-flow: column nowrap;
    margin-top: auto;
    margin-bottom: auto;
  }

  .markBar {
    background-color: #000000;
    margin-top: 2%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    background-color: #00000000;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
    padding: 15px;
    
  }

  .markBarCon {
    background-color: #222222a6;
    display: flex;
    flex-flow: row nowrap;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding: 5px;
    margin-top: 20px;
    margin-bottom: 20px;
    border: 2px solid #a87026;
    box-shadow: 0 0 10px #d6a25e;
  }

  .markBar2 {
    flex-flow: column nowrap;
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: -10%;
  }

  .num {
    font-size: 18px;
    color: #ffffff;

    text-align: center;
  }

  .name {
    color: #ffffff;
    font-size: 18px;
    text-align: center;
    font-family: 'Russo One', sans-serif;
  }

  .num2 {
    font-size: 20px;
    color: white;
    margin-top: 15px;
    text-align: center;
  }

  .name2 {
    color: white;
    font-size: 18px;
    text-align: center;
    font-weight: 500;
  }

  .markBar img {
    width: 70px;
    height: 70px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 20%;
  }

  .markBarMain {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-top: 3%;
    margin-bottom: 5%;
  }

  .stakeMain {
    display: flex;
    flex-flow: column nowrap;
    background-color: black;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.49343487394957986) 0%, rgba(0, 0, 0, 0.804359243697479) 69%, rgba(0, 0, 0, 0.896796218487395) 98%), url('./assets/fire.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    
  }

  .stakeMain2 {
    border-right: 20px #f3ba2f solid;
    border-top: #f3ba2f 20px solid;
  }

  .sticky {
    width: 20%;

    bottom: 0;
    right: 90%;
    margin-top: -10%;
  }

  .items {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }

  .board {
    margin-bottom: 5%;
    padding: 35px;
    border: #ffffff 8px double;
    margin-left: 1%;
    margin-right: 1%;
    background: rgba(46, 46, 46, 0.2);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    width: 90%;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }

  .boardMain {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    
    margin-bottom: 7%;

  }

  .nameStake {
    width: 50%;
  }

  .carrots {
    width: 90px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .egg {
    width: 70px;
    height: 70px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .egg2 {
    width: 95px;
    height: 95px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .boardImg {
    background-color: #ffb963;

  }

  .boardPics {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    margin-bottom: 8%;
  }

  .approveBtns {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    margin-top: 2%;
  }

  .approveBtns1 {
    border: 4px solid;
    border-image-slice: 1;
    border-image-source: linear-gradient(to left, #facc15, #f0d56b);
    background-color: rgba(206, 8, 8, 0);
    box-shadow: 0 0 5px gold;
    color: rgb(255, 255, 255);
    padding: 15px;
    font-size: 23px;
    font-family: 'Russo One', sans-serif;
    width: 350px;
    transition: transform .3s;
  }

  .approveBtns1:hover {
    transform: scale(1.1);
  }

  .approveBtns2:hover {
    transform: scale(1.1);
  }

  .approveBtns3:hover {
    transform: scale(1.1);
  }

  .approveBtns3 {
    border: 4px solid;
    border-image-slice: 1;
    border-image-source: linear-gradient(to left, rgb(231, 80, 80), rgb(206, 8, 8));
    background-color: rgba(206, 8, 8, 0);
    box-shadow: 0 0 5px red;
    color: white;
    color: white;
    padding: 15px;
    font-size: 23px;
    font-family: 'Russo One', sans-serif;
    width: 350px;
    transition: transform .3s;
  }

  .approveBtns3Dis {
    background-color: rgb(224, 111, 111);
    border: none;
    cursor: no-drop;
    color: rgb(187, 183, 183);

    padding: 20px;
    font-size: 23px;
    font-family: 'Russo One', sans-serif;
    width: 400px;
    transition: transform .3s;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  }

  .approveBtns2 {
    background: none;
    color: white;
    padding: 20px;
    border-radius: 10px;
    font-size: 23px;
    border: 4px #70512b solid;

    width: 200px;
    transition: transform .3s;
  }


  .wallet3 {
    
    background-color: black;
    border: solid 3px;
    color: rgb(255, 255, 255);
    padding: 15px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 20px;
    margin-left: auto;
    margin-right: auto;
    width: 270px;
    transition: transform .5s;
    z-index: 1;
    margin-bottom: 10%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;

  }

  .lookDown {
    width: 20px;
    height: 20px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }


  .wallet3:hover {
    cursor: pointer;
  }

  .wallet3::after {
    transition: all 0.8s
  }

  .btn3 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .link {
    text-align: center;
    font-size: 15px;
    margin-top: 5px;
  }

  .images_show {
    width: 90%;
  }

  .hide {
    display: none;
  }

  .stake {
    display: block;
    background-color: red;
    color: white;
    border-radius: 10px;
    border: none;
    width: fit-content;
    padding: 5px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    cursor: pointer;
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
  }

  .stake2 {
    display: block;
    background-color: red;
    color: white;
    border-radius: 10px;
    border: none;
    width: 85px;
    padding: 5px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    cursor: pointer;
    margin-top: 10px;
    text-align: center;
  }

  .stake3 {
    display: block;
    background-color: red;
    color: white;
    border-radius: 10px;
    border: none;
    width: 85px;
    padding: 5px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    cursor: pointer;
    margin-bottom: 10px;
    text-align: center;
  }

  .stake:hover {}

  .hide:hover {}

  .tokenIds {
    display: flex;
    justify-content: center;
    color: white;
    flex-flow: row wrap;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    height: max-content;
    margin-bottom: 10%;
  }

  .tokenIds2 {
    display: flex;
    justify-content: center;
    color: white;
    flex-flow: row wrap;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 40%;
  }


  .tS {
    color: #3a9ae8;
    text-align: center;
    margin-top: 2%;
    font-size: 30px;
    
  }

  .unstakeImg {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;

  }

  .imagesAndBtn {
    justify-content: center;
  }

  .flip-card {
    background-color: transparent;
    width: 150px;
    perspective: 1000px;
    background-color: #e0706c;
    height: fit-content;

    margin-top: 10%;
  }

  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }

  .flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
  }

  .flip-card-front,
  .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  .btnCenter {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .flip-card-front {
    /* background-color: #bbb;*/
    color: black;
  }

  .flip-card-back {
    /*background-color: #2980b9;*/
    color: white;
    transform: rotateY(180deg);
  }

  .loginFormNote {
    height: 90%;
    width: 90%;
    background-color: rgba(255, 255, 255, 0.13);
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    border-radius: 10px;
    backdrop-filter: blur(10px);
    border: 2px solid rgba(255, 255, 255, 0.1);
    box-shadow: 0 0 40px rgba(8, 7, 16, 0.6);
    padding: 50px 35px;
  }

  .formMain {
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.856);
    position: fixed;
    width: 80%;
    height: 80vh;
    top: 50%;
    left: 50%;
    z-index: 1;
    overflow: scroll;
    overflow-x: hidden;
    border-radius: 10px;
    z-index: 100000;
    border: white 3px solid;
  }

  .formMain {
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.856);
    position: fixed;
    width: 80%;
    height: 80vh;
    top: 50%;
    left: 50%;
    z-index: 1;
    overflow: scroll;
    overflow-x: hidden;
    border-radius: 10px;
    z-index: 100000;
    border: white 3px solid;
  }

  .formMain1 {
    transform: translate(-50%, -50%);
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 50%;
    left: 50%;
    z-index: 100000;

    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
  }

  .ownNFT {
    margin-bottom: 40%;
  }

  .elementName {
    font-weight: 500;
    color: red;
    font-size: 20px;
    text-align: center;
  }

  .elementName2 {
    font-weight: 500;
    color: rgb(255, 255, 255);
    font-size: 20px;
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .elementName3 {
    font-weight: 500;
    color: red;
    font-size: 20px;
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .medalDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .closeNote {
    position: absolute;
    right: 0;
    top: 1%;
    color: rgba(255, 255, 255, 0.836);
    font-size: 30px;

    background-color: rgba(172, 67, 67, 0);
    width: 100px;
    height: fit-content;
    cursor: pointer;
    border: none;
  }

  .closeNote:hover,
  .closeNote:focus {
    color: rgb(71, 70, 70);
  }

  .rewards {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;

  }

  .discordB {
    display: none;
  }

  .medal {
    width: 35px;
    height: 35px;
  }

  .stakebtn:hover {
    transform: scale(1.1);
  }

  .stakebtn2:hover {
    transform: scale(1.1);
  }

  .stakebtnDiv {
    
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .rabbitImg {
    width: 110px;
    margin-top: 20%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 2%;
  }

  .rabbitImgLogo {
    width: 110px;
    margin-top: 20%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    border-radius: 100px;
    margin-bottom: 2%;
    border: 2px solid goldenrod;
    box-shadow: 0 0 10px gold;
  }

  .rabbitImg2 {
    width: 110px;
    margin-top: 20%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .stakeT {
    color: #3a9ae8;
    font-size: 45px;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {

  .btns {
    
    width: 150px;
    background-color: #facc15;
    color: rgb(0, 0, 0);
    padding: 5px;
    font-weight: 500;
    margin-bottom: 5%;
    transition: transform .3s;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;

  }

  .btnsDis {
    width: 150px;
    background-color: #77946c;
    border: none;
    color: rgb(255, 255, 255);
    padding: 5px;
    font-weight: 500;
    margin-bottom: 5%;
    transition: transform .3s;
    cursor: no-drop;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  }

  .btns2 {
    width: 150px;
    color: rgb(0, 0, 0);
    padding: 5px;
    font-weight: 500;
    transition: transform .3s;
    background-color: rgb(238, 62, 62);
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    border: none;
  }

  .btns2Dis {
    width: 150px;
    background-color: rgb(196, 134, 134);
    cursor: no-drop;
    color: rgb(255, 255, 255);
    padding: 5px;
    font-weight: 500;
    transition: transform .3s;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  }

  .btns:hover {
    transform: scale(1.1);
  }

  .btns2:hover {
    transform: scale(1.1);
  }

  .btnsMain {
    display: flex;
    flex-flow: column nowrap;
    margin-top: auto;
    margin-bottom: auto;
    display: none;
  }

  .btnsMainBig {
    display: flex;
    flex-flow: column nowrap;
    margin-top: auto;
    margin-bottom: auto;
  }

  .markBar {
    background-color: #000000;
    margin-top: 2%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    background-color: #00000000;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
    padding: 15px;
    
  }

  .markBarCon {
    background-color: #222222a6;
    display: flex;
    flex-flow: row nowrap;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding: 5px;
    margin-top: 20px;
    margin-bottom: 20px;
    border: 2px solid #a87026;
    box-shadow: 0 0 10px #d6a25e;
  }

  .markBar2 {
    flex-flow: column nowrap;
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: -10%;
  }

  .num {
    font-size: 18px;
    color: #ffffff;

    text-align: center;
  }

  .name {
    color: #ffffff;
    font-size: 18px;
    text-align: center;
    font-family: 'Russo One', sans-serif;
  }

  .num2 {
    font-size: 20px;
    color: white;
    margin-top: 15px;
    text-align: center;
  }

  .name2 {
    color: white;
    font-size: 18px;
    text-align: center;
    font-weight: 500;
  }

  .markBar img {
    width: 70px;
    height: 70px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 20%;
  }

  .markBarMain {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-top: 3%;
    margin-bottom: 5%;
  }

  .stakeMain {
    display: flex;
    flex-flow: column nowrap;
    background-color: black;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.49343487394957986) 0%, rgba(0, 0, 0, 0.804359243697479) 69%, rgba(0, 0, 0, 0.896796218487395) 98%), url('./assets/fire.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    
  }

  .stakeMain2 {
    border-right: 20px #f3ba2f solid;
    border-top: #f3ba2f 20px solid;
  }

  .sticky {
    width: 20%;

    bottom: 0;
    right: 90%;
    margin-top: -10%;
  }

  .items {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }

  .board {
    margin-bottom: 5%;
    padding: 35px;
    border: #ffffff 8px double;
    margin-left: 1%;
    margin-right: 1%;
    background: rgba(46, 46, 46, 0.2);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    width: 65%;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }

  .boardMain {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    
    margin-bottom: 7%;

  }

  .nameStake {
    width: 50%;
  }

  .carrots {
    width: 90px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .egg {
    width: 70px;
    height: 70px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .egg2 {
    width: 95px;
    height: 95px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .boardImg {
    background-color: #ffb963;

  }

  .boardPics {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    margin-bottom: 8%;
  }

  .approveBtns {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    margin-top: 2%;
  }

  .approveBtns1 {
    border: 4px solid;
    border-image-slice: 1;
    border-image-source: linear-gradient(to left, #facc15, #f0d56b);
    background-color: rgba(206, 8, 8, 0);
    box-shadow: 0 0 5px gold;
    color: rgb(255, 255, 255);
    padding: 15px;
    font-size: 23px;
    font-family: 'Russo One', sans-serif;
    width: 400px;
    transition: transform .3s;
  }

  .approveBtns1:hover {
    transform: scale(1.1);
  }

  .approveBtns2:hover {
    transform: scale(1.1);
  }

  .approveBtns3:hover {
    transform: scale(1.1);
  }

  .approveBtns3 {
    border: 4px solid;
    border-image-slice: 1;
    border-image-source: linear-gradient(to left, rgb(231, 80, 80), rgb(206, 8, 8));
    background-color: rgba(206, 8, 8, 0);
    box-shadow: 0 0 5px red;
    color: white;
    color: white;
    padding: 15px;
    font-size: 23px;
    font-family: 'Russo One', sans-serif;
    width: 400px;
    transition: transform .3s;

  }

  .approveBtns3Dis {
    background-color: rgb(224, 111, 111);
    border: none;
    cursor: no-drop;
    color: rgb(187, 183, 183);

    padding: 20px;
    font-size: 23px;
    font-family: 'Russo One', sans-serif;
    width: 400px;
    transition: transform .3s;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  }

  .approveBtns2 {
    background: none;
    color: white;
    padding: 20px;
    border-radius: 10px;
    font-size: 23px;
    border: 4px #70512b solid;

    width: 200px;
    transition: transform .3s;
  }


  .wallet3 {
    
    background-color: black;
    border: solid 3px;
    color: rgb(255, 255, 255);
    padding: 15px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 20px;
    margin-left: auto;
    margin-right: auto;
    width: 270px;
    transition: transform .5s;
    z-index: 1;
    margin-bottom: 10%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;

  }

  .lookDown {
    width: 20px;
    height: 20px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }


  .wallet3:hover {
    cursor: pointer;
  }

  .wallet3::after {
    transition: all 0.8s
  }

  .btn3 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .link {
    text-align: center;
    font-size: 15px;
    margin-top: 5px;
  }

  .images_show {
    width: 90%;
  }

  .hide {
    display: none;
  }

  .stake {
    display: block;
    background-color: red;
    color: white;
    border-radius: 10px;
    border: none;
    width: fit-content;
    padding: 5px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    cursor: pointer;
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
  }

  .stake2 {
    display: block;
    background-color: red;
    color: white;
    border-radius: 10px;
    border: none;
    width: 85px;
    padding: 5px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    cursor: pointer;
    margin-top: 10px;
    text-align: center;
  }

  .stake3 {
    display: block;
    background-color: red;
    color: white;
    border-radius: 10px;
    border: none;
    width: 85px;
    padding: 5px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    cursor: pointer;
    margin-bottom: 10px;
    text-align: center;
  }

  .stake:hover {}

  .hide:hover {}

  .tokenIds {
    display: flex;
    justify-content: center;
    color: white;
    flex-flow: row wrap;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    height: max-content;
    margin-bottom: 10%;
  }

  .tokenIds2 {
    display: flex;
    justify-content: center;
    color: white;
    flex-flow: row wrap;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 25%;
  }

  .tS {
    color: #3a9ae8;
    text-align: center;
    margin-top: 2%;
    font-size: 40px;
    
  }

  .unstakeImg {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;

  }

  .imagesAndBtn {
    justify-content: center;
  }

  .flip-card {
    background-color: transparent;
    width: 150px;
    perspective: 1000px;
    background-color: #e0706c;
    height: fit-content;

    margin-top: 10%;
  }

  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }

  .flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
  }

  .flip-card-front,
  .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  .btnCenter {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .flip-card-front {
    /* background-color: #bbb;*/
    color: black;
  }

  .flip-card-back {
    /*background-color: #2980b9;*/
    color: white;
    transform: rotateY(180deg);
  }

  .loginFormNote {
    height: 90%;
    width: 90%;
    background-color: rgba(255, 255, 255, 0.13);
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    border-radius: 10px;
    backdrop-filter: blur(10px);
    border: 2px solid rgba(255, 255, 255, 0.1);
    box-shadow: 0 0 40px rgba(8, 7, 16, 0.6);
    padding: 50px 35px;
  }

  .formMain {
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.856);
    position: fixed;
    width: 80%;
    height: 80vh;
    top: 50%;
    left: 50%;
    z-index: 1;
    overflow: scroll;
    overflow-x: hidden;
    border-radius: 10px;
    z-index: 100000;
    border: white 3px solid;
  }

  .formMain {
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.856);
    position: fixed;
    width: 80%;
    height: 80vh;
    top: 50%;
    left: 50%;
    z-index: 1;
    overflow: scroll;
    overflow-x: hidden;
    border-radius: 10px;
    z-index: 100000;
    border: white 3px solid;
  }

  .formMain1 {
    transform: translate(-50%, -50%);
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 50%;
    left: 50%;
    z-index: 100000;

    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
  }

  .ownNFT {
    margin-top: 18%;
    margin-bottom: 5.5%;
  }

  .elementName {
    font-weight: 500;
    color: red;
    font-size: 20px;
    text-align: center;
  }

  .elementName2 {
    font-weight: 500;
    color: rgb(255, 255, 255);
    font-size: 20px;
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .elementName3 {
    font-weight: 500;
    color: red;
    font-size: 20px;
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .medalDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .closeNote {
    position: absolute;
    right: 0;
    top: 1%;
    color: rgba(255, 255, 255, 0.836);
    font-size: 30px;

    background-color: rgba(172, 67, 67, 0);
    width: 100px;
    height: fit-content;
    cursor: pointer;
    border: none;
  }

  .closeNote:hover,
  .closeNote:focus {
    color: rgb(71, 70, 70);
  }

  .rewards {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;

  }

  .discordB {
    display: none;
  }

  .medal {
    width: 35px;
    height: 35px;
  }

  .stakebtn:hover {
    transform: scale(1.1);
  }

  .stakebtn2:hover {
    transform: scale(1.1);
  }

  .stakebtnDiv {
    
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .rabbitImg {
    width: 110px;
    margin-top: 20%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 2%;
  }

  .rabbitImgLogo {
    width: 110px;
    margin-top: 20%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    border-radius: 100px;
    margin-bottom: 2%;
    border: 2px solid goldenrod;
    box-shadow: 0 0 10px gold;
  }

  .rabbitImg2 {
    width: 110px;
    margin-top: 20%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .stakeT {
    color: #3a9ae8;
    font-size: 45px;
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {

  .btns {
    
    width: 150px;
    background-color: #facc15;
    color: rgb(0, 0, 0);
    padding: 5px;
    font-weight: 500;
    margin-bottom: 5%;
    transition: transform .3s;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;

  }

  .btnsDis {
    width: 150px;
    background-color: #77946c;
    border: none;
    color: rgb(255, 255, 255);
    padding: 5px;
    font-weight: 500;
    margin-bottom: 5%;
    transition: transform .3s;
    cursor: no-drop;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  }

  .btns2 {
    width: 150px;
    color: rgb(0, 0, 0);
    padding: 5px;
    font-weight: 500;
    transition: transform .3s;
    background-color: rgb(238, 62, 62);
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    border: none;
  }

  .btns2Dis {
    width: 150px;
    background-color: rgb(196, 134, 134);
    cursor: no-drop;
    color: rgb(255, 255, 255);
    padding: 5px;
    font-weight: 500;
    transition: transform .3s;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  }

  .btns:hover {
    transform: scale(1.1);
  }

  .btns2:hover {
    transform: scale(1.1);
  }

  .btnsMain {
    display: flex;
    flex-flow: column nowrap;
    margin-top: auto;
    margin-bottom: auto;
    display: none;
  }

  .btnsMainBig {
    display: flex;
    flex-flow: column nowrap;
    margin-top: auto;
    margin-bottom: auto;
  }

  .markBar {
    background-color: #000000;
    margin-top: 2%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    background-color: #00000000;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
    padding: 15px;
    
  }

  .markBarCon {
    background-color: #222222a6;
    display: flex;
    flex-flow: row nowrap;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding: 5px;
    margin-top: 20px;
    margin-bottom: 20px;
    border: 2px solid #a87026;
    box-shadow: 0 0 10px #d6a25e;
  }

  .markBar2 {
    flex-flow: column nowrap;
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: -10%;
  }

  .num {
    font-size: 18px;
    color: #ffffff;

    text-align: center;
  }

  .name {
    color: #ffffff;
    font-size: 18px;
    text-align: center;
    font-family: 'Russo One', sans-serif;
  }

  .num2 {
    font-size: 20px;
    color: white;
    margin-top: 15px;
    text-align: center;
  }

  .name2 {
    color: white;
    font-size: 18px;
    text-align: center;
    font-weight: 500;
  }

  .markBar img {
    width: 70px;
    height: 70px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 20%;
  }

  .markBarMain {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-top: 3%;
    margin-bottom: 5%;
  }

  .stakeMain {
    display: flex;
    flex-flow: column nowrap;
    background-color: black;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.49343487394957986) 0%, rgba(0, 0, 0, 0.804359243697479) 69%, rgba(0, 0, 0, 0.896796218487395) 98%), url('./assets/fire.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    
  }

  .stakeMain2 {
    border-right: 20px #f3ba2f solid;
    border-top: #f3ba2f 20px solid;
  }

  .sticky {
    width: 20%;

    bottom: 0;
    right: 90%;
    margin-top: -10%;
  }

  .items {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }

  .board {
    margin-bottom: 5%;
    padding: 35px;
    border: #ffffff 8px double;
    margin-left: 1%;
    margin-right: 1%;
    background: rgba(46, 46, 46, 0.2);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    width: 65%;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }

  .boardMain {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    
    margin-bottom: 7%;
  }

  .nameStake {
    width: 50%;
  }

  .carrots {
    width: 90px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .egg {
    width: 70px;
    height: 70px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .egg2 {
    width: 95px;
    height: 95px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .boardImg {
    background-color: #ffb963;

  }

  .boardPics {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    margin-bottom: 8%;
  }

  .approveBtns {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    margin-top: 2%;
  }

  .approveBtns1 {
    border: 4px solid;
    border-image-slice: 1;
    border-image-source: linear-gradient(to left, #facc15, #f0d56b);
    background-color: rgba(206, 8, 8, 0);
    box-shadow: 0 0 5px gold;
    color: rgb(255, 255, 255);
    padding: 15px;
    font-size: 23px;
    font-family: 'Russo One', sans-serif;
    width: 400px;
    transition: transform .3s;
  }

  .approveBtns1:hover {
    transform: scale(1.1);
  }

  .approveBtns2:hover {
    transform: scale(1.1);
  }

  .approveBtns3:hover {
    transform: scale(1.1);
  }

  .approveBtns3 {
    border: 4px solid;
    border-image-slice: 1;
    border-image-source: linear-gradient(to left, rgb(231, 80, 80), rgb(206, 8, 8));
    background-color: rgba(206, 8, 8, 0);
    box-shadow: 0 0 5px red;
    color: white;
    color: white;
    padding: 15px;
    font-size: 23px;
    font-family: 'Russo One', sans-serif;
    width: 400px;
    transition: transform .3s;

  }

  .approveBtns3Dis {
    background-color: rgb(224, 111, 111);
    border: none;
    cursor: no-drop;
    color: rgb(187, 183, 183);

    padding: 20px;
    font-size: 23px;
    font-family: 'Russo One', sans-serif;
    width: 400px;
    transition: transform .3s;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  }

  .approveBtns2 {
    background: none;
    color: white;
    padding: 20px;
    border-radius: 10px;
    font-size: 23px;
    border: 4px #70512b solid;

    width: 200px;
    transition: transform .3s;
  }


  .wallet3 {
    
    background-color: black;
    border: solid 3px;
    color: rgb(255, 255, 255);
    padding: 15px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 20px;
    margin-left: auto;
    margin-right: auto;
    width: 270px;
    transition: transform .5s;
    z-index: 1;
    margin-bottom: 10%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;

  }

  .lookDown {
    width: 20px;
    height: 20px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }


  .wallet3:hover {
    cursor: pointer;
  }

  .wallet3::after {
    transition: all 0.8s
  }

  .btn3 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .link {
    text-align: center;
    font-size: 15px;
    margin-top: 5px;
  }

  .images_show {
    width: 90%;
  }

  .hide {
    display: none;
  }

  .stake {
    display: block;
    background-color: red;
    color: white;
    border-radius: 10px;
    border: none;
    width: fit-content;
    padding: 5px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    cursor: pointer;
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
  }

  .stake2 {
    display: block;
    background-color: red;
    color: white;
    border-radius: 10px;
    border: none;
    width: 85px;
    padding: 5px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    cursor: pointer;
    margin-top: 10px;
    text-align: center;
  }

  .stake3 {
    display: block;
    background-color: red;
    color: white;
    border-radius: 10px;
    border: none;
    width: 85px;
    padding: 5px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    cursor: pointer;
    margin-bottom: 10px;
    text-align: center;
  }

  .stake:hover {}

  .hide:hover {}

  .tokenIds {
    display: flex;
    justify-content: center;
    color: white;
    flex-flow: row wrap;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    height: max-content;
    margin-bottom: 10%;
  }

  .tokenIds2 {
    display: flex;
    justify-content: center;
    color: white;
    flex-flow: row wrap;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20%;
  }

  .tS {
    color: #3a9ae8;
    text-align: center;
    margin-top: 2%;
    font-size: 40px;
    
  }

  .unstakeImg {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;

  }

  .imagesAndBtn {
    justify-content: center;
  }

  .flip-card {
    background-color: transparent;
    width: 150px;
    perspective: 1000px;
    background-color: #e0706c;
    height: fit-content;

    margin-top: 10%;
  }

  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }

  .flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
  }

  .flip-card-front,
  .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  .btnCenter {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .flip-card-front {
    /* background-color: #bbb;*/
    color: black;
  }

  .flip-card-back {
    /*background-color: #2980b9;*/
    color: white;
    transform: rotateY(180deg);
  }

  .loginFormNote {
    height: 90%;
    width: 90%;
    background-color: rgba(255, 255, 255, 0.13);
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    border-radius: 10px;
    backdrop-filter: blur(10px);
    border: 2px solid rgba(255, 255, 255, 0.1);
    box-shadow: 0 0 40px rgba(8, 7, 16, 0.6);
    padding: 50px 35px;
  }
  .formMain {
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.856);
    position: fixed;
    width: 80%;
    height: 80vh;
    top: 50%;
    left: 50%;
    z-index: 1;
    overflow: scroll;
    overflow-x: hidden;
    border-radius: 10px;
    z-index: 100000;
    border: white 3px solid;

  }

  .formMain1 {
    transform: translate(-50%, -50%);
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 50%;
    left: 50%;
    z-index: 100000;

    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
  }

  .ownNFT {
    margin-top: 13%;
    margin-bottom: 5.5%;
  }


  .elementName {
    font-weight: 500;
    color: red;
    font-size: 20px;
    text-align: center;
  }

  .elementName2 {
    font-weight: 500;
    color: rgb(255, 255, 255);
    font-size: 20px;
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .elementName3 {
    font-weight: 500;
    color: red;
    font-size: 20px;
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .medalDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .closeNote {
    position: absolute;
    right: 0;
    top: 1%;
    color: rgba(255, 255, 255, 0.836);
    font-size: 30px;

    background-color: rgba(172, 67, 67, 0);
    width: 100px;
    height: fit-content;
    cursor: pointer;
    border: none;
  }

  .closeNote:hover,
  .closeNote:focus {
    color: rgb(71, 70, 70);
  }

  .rewards {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;

  }

  .discordB {
    display: none;
  }

  .medal {
    width: 35px;
    height: 35px;
  }

  .stakebtn:hover {
    transform: scale(1.1);
  }

  .stakebtn2:hover {
    transform: scale(1.1);
  }

  .stakebtnDiv {
    
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .rabbitImg {
    width: 110px;
    margin-top: 20%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 2%;
  }

  .rabbitImgLogo {
    width: 110px;
    margin-top: 20%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    border-radius: 100px;
    margin-bottom: 2%;
    border: 2px solid goldenrod;
    box-shadow: 0 0 10px gold;
  }

  .rabbitImg2 {
    width: 110px;
    margin-top: 20%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .stakeT {
    color: #3a9ae8;
    font-size: 45px;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1919px) {

  .btns {
    
    width: 150px;
    background-color: #facc15;
    color: rgb(0, 0, 0);
    padding: 5px;
    font-weight: 500;
    margin-bottom: 5%;
    transition: transform .3s;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;

  }

  .btnsDis {
    width: 150px;
    background-color: #77946c;
    border: none;
    color: rgb(255, 255, 255);
    padding: 5px;
    font-weight: 500;
    margin-bottom: 5%;
    transition: transform .3s;
    cursor: no-drop;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  }

  .btns2 {
    width: 150px;
    color: rgb(0, 0, 0);
    padding: 5px;
    font-weight: 500;
    transition: transform .3s;
    background-color: rgb(238, 62, 62);
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    border: none;
  }

  .btns2Dis {
    width: 150px;
    background-color: rgb(196, 134, 134);
    cursor: no-drop;
    color: rgb(255, 255, 255);
    padding: 5px;
    font-weight: 500;
    transition: transform .3s;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  }

  .btns:hover {
    transform: scale(1.1);
  }

  .btns2:hover {
    transform: scale(1.1);
  }

  .btnsMain {
    display: flex;
    flex-flow: column nowrap;
    margin-top: auto;
    margin-bottom: auto;
    display: none;
  }

  .btnsMainBig {
    display: flex;
    flex-flow: column nowrap;
    margin-top: auto;
    margin-bottom: auto;
  }

  .markBar {
    background-color: #000000;
    margin-top: 2%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    background-image: url('./assets/ticker.jpg');
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
    border: 2px solid #a87026;
    box-shadow: 0 0 10px #d6a25e;
    padding: 15px;
    
  }

  .markBarCon {
    background-color: #222222a6;
    display: flex;
    flex-flow: row nowrap;
    width: 20%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding: 5px;
  }

  .markBar2 {
    flex-flow: column nowrap;
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: -10%;
  }

  .num {
    font-size: 18px;
    color: #ffffff;

    text-align: center;
  }

  .name {
    color: #ffffff;
    font-size: 18px;
    text-align: center;
    font-family: 'Russo One', sans-serif;
  }

  .num2 {
    font-size: 20px;
    color: white;
    margin-top: 15px;
    text-align: center;
  }

  .name2 {
    color: white;
    font-size: 18px;
    text-align: center;
    font-weight: 500;
  }

  .markBar img {
    width: 70px;
    height: 70px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 20%;
  }

  .markBarMain {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-top: 3%;
    margin-bottom: 5%;
  }

  .stakeMain {
    display: flex;
    flex-flow: column nowrap;
    background-color: black;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.49343487394957986) 0%, rgba(0, 0, 0, 0.804359243697479) 69%, rgba(0, 0, 0, 0.896796218487395) 98%), url('./assets/fire.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    
  }

  .stakeMain2 {
    border-right: 20px #f3ba2f solid;
    border-top: #f3ba2f 20px solid;
  }

  .sticky {
    width: 20%;

    bottom: 0;
    right: 90%;
    margin-top: -10%;
  }

  .items {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }

  .board {
    margin-bottom: 5%;
    padding: 35px;
    border: #ffffff 8px double;
    margin-left: 1%;
    margin-right: 1%;
    background: rgba(46, 46, 46, 0.2);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    width: 42%;
  }

  .boardMain {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
    width: 85%;
    
  }

  .nameStake {
    width: 50%;
  }

  .carrots {
    width: 90px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .egg {
    width: 70px;
    height: 70px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .egg2 {
    width: 95px;
    height: 95px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .boardImg {
    background-color: #ffb963;

  }

  .boardPics {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    margin-bottom: 8%;
  }

  .approveBtns {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    margin-top: 2%;
  }

  .approveBtns1 {
    border: 4px solid;
    border-image-slice: 1;
    border-image-source: linear-gradient(to left, #facc15, #f0d56b);
    background-color: rgba(206, 8, 8, 0);
    box-shadow: 0 0 5px gold;
    color: rgb(255, 255, 255);
    padding: 15px;
    font-size: 23px;
    font-family: 'Russo One', sans-serif;
    width: 400px;
    transition: transform .3s;
  }

  .approveBtns1:hover {
    transform: scale(1.1);
  }

  .approveBtns2:hover {
    transform: scale(1.1);
  }

  .approveBtns3:hover {
    transform: scale(1.1);
  }

  .approveBtns3 {
    border: 4px solid;
    border-image-slice: 1;
    border-image-source: linear-gradient(to left, rgb(231, 80, 80), rgb(206, 8, 8));
    background-color: rgba(206, 8, 8, 0);
    box-shadow: 0 0 5px red;
    color: white;
    color: white;
    padding: 15px;
    font-size: 23px;
    font-family: 'Russo One', sans-serif;
    width: 400px;
    transition: transform .3s;

  }

  .approveBtns3Dis {
    background-color: rgb(224, 111, 111);
    border: none;
    cursor: no-drop;
    color: rgb(187, 183, 183);

    padding: 20px;
    font-size: 23px;
    font-family: 'Russo One', sans-serif;
    width: 400px;
    transition: transform .3s;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  }

  .approveBtns2 {
    background: none;
    color: white;
    padding: 20px;
    border-radius: 10px;
    font-size: 23px;
    border: 4px #70512b solid;

    width: 200px;
    transition: transform .3s;
  }


  .wallet3 {
    
    background-color: black;
    border: solid 3px;
    color: rgb(255, 255, 255);
    padding: 15px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 20px;
    margin-left: auto;
    margin-right: auto;
    width: 270px;
    transition: transform .5s;
    z-index: 1;
    margin-bottom: 10%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;

  }

  .lookDown {
    width: 20px;
    height: 20px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }


  .wallet3:hover {
    cursor: pointer;
  }

  .wallet3::after {
    transition: all 0.8s
  }

  .btn3 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .link {
    text-align: center;
    font-size: 15px;
    margin-top: 5px;
  }

  .images_show {
    width: 90%;
  }

  .hide {
    display: none;
  }

  .stake {
    display: block;
    background-color: red;
    color: white;
    border-radius: 10px;
    border: none;
    width: fit-content;
    padding: 5px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    cursor: pointer;
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
  }

  .stake2 {
    display: block;
    background-color: red;
    color: white;
    border-radius: 10px;
    border: none;
    width: 85px;
    padding: 5px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    cursor: pointer;
    margin-top: 10px;
    text-align: center;
  }

  .stake3 {
    display: block;
    background-color: red;
    color: white;
    border-radius: 10px;
    border: none;
    width: 85px;
    padding: 5px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    cursor: pointer;
    margin-bottom: 10px;
    text-align: center;
  }

  .stake:hover {}

  .hide:hover {}

  .tokenIds {
    display: flex;
    justify-content: center;
    color: white;
    flex-flow: row wrap;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    height: max-content;
    margin-bottom: 10%;
  }

  .tokenIds2 {
    display: flex;
    justify-content: center;
    color: white;
    flex-flow: row wrap;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 15%;
  }

  .tS {
    color: #3a9ae8;
    text-align: center;
    margin-top: 2%;
    font-size: 40px;
    
  }

  .unstakeImg {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;

  }

  .imagesAndBtn {
    justify-content: center;
  }

  .flip-card {
    background-color: transparent;
    width: 150px;
    perspective: 1000px;
    background-color: #e0706c;
    height: fit-content;

    margin-top: 10%;
  }

  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }

  .flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
  }

  .flip-card-front,
  .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  .btnCenter {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .flip-card-front {
    /* background-color: #bbb;*/
    color: black;
  }

  .flip-card-back {
    /*background-color: #2980b9;*/
    color: white;
    transform: rotateY(180deg);
  }

  .loginFormNote {
    height: 90%;
    width: 90%;
    background-color: rgba(255, 255, 255, 0.13);
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    border-radius: 10px;
    backdrop-filter: blur(10px);
    border: 2px solid rgba(255, 255, 255, 0.1);
    box-shadow: 0 0 40px rgba(8, 7, 16, 0.6);
    padding: 50px 35px;
  }

  
  .formMain {
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.856);
    position: fixed;
    width: 80%;
    height: 80vh;
    top: 50%;
    left: 50%;
    z-index: 1;
    overflow: scroll;
    overflow-x: hidden;
    border-radius: 10px;
    z-index: 100000;
    border: white 3px solid;

  }

  .formMain2 {
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.856);
    position: fixed;
    width: 50%;
    height: 80vh;
    top: 50%;
    left: 50%;
    z-index: 1;
    overflow: scroll;
    overflow-x: hidden;
    border-radius: 10px;
    z-index: 100000;
    border: white 3px solid;
    padding: 10px;
  }

  .formMain1 {
    transform: translate(-50%, -50%);
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 50%;
    left: 50%;
    z-index: 100000;
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
  }

  .formMain1-1 {
    transform: translate(-50%, -50%);
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 50%;
    left: 50%;
    z-index: 100000;
    background-color: #00000062;
  }

  .ownNFT {
    margin-top: 8%;
    margin-bottom: 5.5%;
  }
  .elementName {
    font-weight: 500;
    color: red;
    font-size: 20px;
    text-align: center;
  }

  .elementName2 {
    font-weight: 500;
    color: rgb(255, 255, 255);
    font-size: 20px;
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .elementName3 {
    font-weight: 500;
    color: red;
    font-size: 20px;
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .medalDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .closeNote {
    position: absolute;
    right: 0;
    top: 1%;
    color: rgba(255, 255, 255, 0.836);
    font-size: 30px;

    background-color: rgba(172, 67, 67, 0);
    width: 100px;
    height: fit-content;
    cursor: pointer;
    border: none;
  }

  .closeNote:hover,
  .closeNote:focus {
    color: rgb(71, 70, 70);
  }

  .rewards {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;

  }

  .discordB {
    display: none;
  }

  .medal {
    width: 35px;
    height: 35px;
  }

  .stakebtn:hover {
    transform: scale(1.1);
  }

  .stakebtn2:hover {
    transform: scale(1.1);
  }

  .stakebtnDiv {
    
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .rabbitImg {
    width: 110px;
    margin-top: 20%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 2%;
  }

  .rabbitImgLogo {
    width: 110px;
    margin-top: 20%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    border-radius: 100px;
    margin-bottom: 2%;
    border: 2px solid goldenrod;
    box-shadow: 0 0 10px gold;
  }

  .rabbitImg2 {
    width: 110px;
    margin-top: 20%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .stakeT {
    color: #3a9ae8;
    font-size: 45px;
  }
}

@media screen and (min-width: 1920px) {

  .btns {
    
    width: 150px;
    background-color: #facc15;
    color: rgb(0, 0, 0);
    padding: 5px;
    font-weight: 500;
    margin-bottom: 5%;
    transition: transform .3s;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;

  }

  .btnsDis {
    width: 150px;
    background-color: #77946c;
    border: none;
    color: rgb(255, 255, 255);
    padding: 5px;
    font-weight: 500;
    margin-bottom: 5%;
    transition: transform .3s;
    cursor: no-drop;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  }

  .btns2 {
    width: 150px;
    color: rgb(0, 0, 0);
    padding: 5px;
    font-weight: 500;
    transition: transform .3s;
    background-color: rgb(238, 62, 62);
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    border: none;
  }

  .btns2Dis {
    width: 150px;
    background-color: rgb(196, 134, 134);
    cursor: no-drop;
    color: rgb(255, 255, 255);
    padding: 5px;
    font-weight: 500;
    transition: transform .3s;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  }

  .btns:hover {
    transform: scale(1.1);
  }

  .btns2:hover {
    transform: scale(1.1);
  }

  .btnsMain {
    display: flex;
    flex-flow: column nowrap;
    margin-top: auto;
    margin-bottom: auto;
    display: none;
  }

  .btnsMainBig {
    display: flex;
    flex-flow: column nowrap;
    margin-top: auto;
    margin-bottom: auto;
  }

  .markBar {
    background-color: #000000;
    margin-top: 2%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    background-image: url('./assets/ticker.jpg');
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
    border: 4px solid #a87026;
    box-shadow: 0 0 10px #d6a25e;
    padding: 15px;
    
  }

  .markBarCon {
    background-color: #222222a6;
    display: flex;
    flex-flow: row nowrap;
    width: 20%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding: 5px;
  }

  .markBar2 {
    flex-flow: column nowrap;
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: -10%;
  }

  .num {
    font-size: 25px;
    color: #ffffff;

    text-align: center;
  }

  .name {
    color: #ffffff;
    font-size: 25px;
    text-align: center;
    font-family: 'Russo One', sans-serif;
  }

  .num2 {
    font-size: 30px;
    color: white;
    margin-top: 15px;
    text-align: center;
  }

  .name2 {
    color: white;
    font-size: 28px;
    text-align: center;
    font-weight: 500;
  }

  .markBar img {
    width: 95px;
    height: 95px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 20%;
  }

  .markBarMain {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-top: 3%;
    margin-bottom: 5%;
  }

  .stakeMain {
    display: flex;
    flex-flow: column nowrap;
    background-color: black;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.49343487394957986) 0%, rgba(0, 0, 0, 0.804359243697479) 69%, rgba(0, 0, 0, 0.896796218487395) 98%), url('./assets/fire.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    
  }

  .stakeMain2 {
    border-right: 20px #f3ba2f solid;
    border-top: #f3ba2f 20px solid;
  }

  .sticky {
    width: 20%;

    bottom: 0;
    right: 90%;
    margin-top: -10%;
  }

  .items {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }

  .board {
    margin-bottom: 5%;
    padding: 35px;
    border: #ffffff 12px double;
    margin-left: 1%;
    margin-right: 1%;
    background: rgba(46, 46, 46, 0.2);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    width: 42%;
  }

  .boardMain {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
    width: 85%;
    
  }

  .nameStake {
    width: 50%;
  }

  .carrots {
    width: 90px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .egg {
    width: 70px;
    height: 70px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .egg2 {
    width: 95px;
    height: 95px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .boardImg {
    background-color: #ffb963;

  }

  .boardPics {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    margin-bottom: 8%;
  }

  .approveBtns {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    margin-top: 2%;
  }

  .approveBtns1 {
    border: 5px solid;
    border-image-slice: 1;
    border-image-source: linear-gradient(to left, #facc15, #f0d56b);
    background-color: rgba(206, 8, 8, 0);
    box-shadow: 0 0 5px gold;
    color: rgb(255, 255, 255);
    padding: 18px;
    font-size: 35px;
    font-family: 'Russo One', sans-serif;
    width: 500px;
    transition: transform .3s;
  }

  .approveBtns1:hover {
    transform: scale(1.1);
  }

  .approveBtns2:hover {
    transform: scale(1.1);
  }

  .approveBtns3:hover {
    transform: scale(1.1);
  }

  .approveBtns3 {
    border: 5px solid;
    border-image-slice: 1;
    border-image-source: linear-gradient(to left, rgb(231, 80, 80), rgb(206, 8, 8));
    background-color: rgba(206, 8, 8, 0);
    box-shadow: 0 0 5px red;
    color: white;
    color: white;
    padding: 18px;
    font-size: 35px;
    font-family: 'Russo One', sans-serif;
    width: 500px;
    transition: transform .3s;

  }

  .approveBtns3Dis {
    background-color: rgb(224, 111, 111);
    border: none;
    cursor: no-drop;
    color: rgb(187, 183, 183);

    padding: 20px;
    font-size: 23px;
    font-family: 'Russo One', sans-serif;
    width: 400px;
    transition: transform .3s;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  }

  .approveBtns2 {
    background: none;
    color: white;
    padding: 20px;
    border-radius: 10px;
    font-size: 23px;
    border: 4px #70512b solid;

    width: 200px;
    transition: transform .3s;
  }


  .wallet3 {
    
    background-color: black;
    border: solid 3px;
    color: rgb(255, 255, 255);
    padding: 15px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 30px;
    margin-left: auto;
    margin-right: auto;
    width: 350px;
    transition: transform .5s;
    z-index: 1;
    margin-bottom: 10%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;

  }

  .lookDown {
    width: 20px;
    height: 20px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }


  .wallet3:hover {
    cursor: pointer;
  }

  .wallet3::after {
    transition: all 0.8s
  }

  .btn3 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .link {
    text-align: center;
    font-size: 15px;
    margin-top: 5px;
  }

  .images_show {
    width: 90%;
  }

  .hide {
    display: none;
  }

  .stake {
    display: block;
    background-color: red;
    color: white;
    border-radius: 10px;
    border: none;
    width: fit-content;
    padding: 5px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    cursor: pointer;
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
  }

  .stake2 {
    display: block;
    background-color: red;
    color: white;
    border-radius: 10px;
    border: none;
    width: 85px;
    padding: 5px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    cursor: pointer;
    margin-top: 10px;
    text-align: center;
  }

  .stake3 {
    display: block;
    background-color: red;
    color: white;
    border-radius: 10px;
    border: none;
    width: 85px;
    padding: 5px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    cursor: pointer;
    margin-bottom: 10px;
    text-align: center;
  }

  .stake:hover {}

  .hide:hover {}

  .tokenIds {
    display: flex;
    justify-content: center;
    color: white;
    flex-flow: row wrap;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    height: max-content;
    margin-bottom: 10%;
  }

  .tokenIds2 {
    display: flex;
    justify-content: center;
    color: white;
    flex-flow: row wrap;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 15%;
  }

  .tS {
    color: #3a9ae8;
    text-align: center;
    margin-top: 2%;
    font-size: 40px;
    
  }

  .unstakeImg {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;

  }

  .imagesAndBtn {
    justify-content: center;
  }

  .flip-card {
    background-color: transparent;
    width: 150px;
    perspective: 1000px;
    background-color: #e0706c;
    height: fit-content;

    margin-top: 10%;
  }

  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }

  .flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
  }

  .flip-card-front,
  .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  .btnCenter {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .flip-card-front {
    /* background-color: #bbb;*/
    color: black;
  }

  .flip-card-back {
    /*background-color: #2980b9;*/
    color: white;
    transform: rotateY(180deg);
  }

  .loginFormNote {
    height: 90%;
    width: 90%;
    background-color: rgba(255, 255, 255, 0.13);
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    border-radius: 10px;
    backdrop-filter: blur(10px);
    border: 2px solid rgba(255, 255, 255, 0.1);
    box-shadow: 0 0 40px rgba(8, 7, 16, 0.6);
    padding: 50px 35px;
  }

  .formMain {
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.856);
    position: fixed;
    width: 80%;
    height: 80vh;
    top: 50%;
    left: 50%;
    z-index: 1;
    overflow: scroll;
    overflow-x: hidden;
    border-radius: 10px;
    z-index: 100000;
    border: white 3px solid;
  }

  .formMain1 {
    transform: translate(-50%, -50%);
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 50%;
    left: 50%;
    z-index: 100000;

    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
  }

  .ownNFT {
    margin-bottom: 12%;
  }


  .elementName {
    font-weight: 500;
    color: red;
    font-size: 20px;
    text-align: center;
  }

  .elementName2 {
    font-weight: 500;
    color: rgb(255, 255, 255);
    font-size: 20px;
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .elementName3 {
    font-weight: 500;
    color: red;
    font-size: 20px;
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .medalDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .closeNote {
    position: absolute;
    right: 0;
    top: 1%;
    color: rgba(255, 255, 255, 0.836);
    font-size: 30px;

    background-color: rgba(172, 67, 67, 0);
    width: 100px;
    height: fit-content;
    cursor: pointer;
    border: none;
  }

  .closeNote:hover,
  .closeNote:focus {
    color: rgb(71, 70, 70);
  }

  .rewards {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;

  }

  .discordB {
    display: none;
  }

  .medal {
    width: 35px;
    height: 35px;
  }

  .stakebtn:hover {
    transform: scale(1.1);
  }

  .stakebtn2:hover {
    transform: scale(1.1);
  }

  .stakebtnDiv {
    
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .rabbitImg {
    width: 155px;
    margin-top: 20%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 2%;
  }

  .rabbitImgLogo {
    width: 155px;
    margin-top: 20%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    border-radius: 100px;
    margin-bottom: 2%;
    border: 2px solid goldenrod;
    box-shadow: 0 0 10px gold;
  }

  .rabbitImg2 {
    width: 155px;
    margin-top: 20%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .stakeT {
    color: #3a9ae8;
    font-size: 60px;
  }
}

@media screen and (min-width: 1921px) and (max-width: 3839px) {

  .btns {
    
    width: 150px;
    background-color: #facc15;
    color: rgb(0, 0, 0);
    padding: 5px;
    font-weight: 500;
    margin-bottom: 5%;
    transition: transform .3s;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;

  }

  .btnsDis {
    width: 150px;
    background-color: #77946c;
    border: none;
    color: rgb(255, 255, 255);
    padding: 5px;
    font-weight: 500;
    margin-bottom: 5%;
    transition: transform .3s;
    cursor: no-drop;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  }

  .btns2 {
    width: 150px;
    color: rgb(0, 0, 0);
    padding: 5px;
    font-weight: 500;
    transition: transform .3s;
    background-color: rgb(238, 62, 62);
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    border: none;
  }

  .btns2Dis {
    width: 150px;
    background-color: rgb(196, 134, 134);
    cursor: no-drop;
    color: rgb(255, 255, 255);
    padding: 5px;
    font-weight: 500;
    transition: transform .3s;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  }

  .btns:hover {
    transform: scale(1.1);
  }

  .btns2:hover {
    transform: scale(1.1);
  }

  .btnsMain {
    display: flex;
    flex-flow: column nowrap;
    margin-top: auto;
    margin-bottom: auto;
    display: none;
  }

  .btnsMainBig {
    display: flex;
    flex-flow: column nowrap;
    margin-top: auto;
    margin-bottom: auto;
  }

  .markBar {
    background-color: #000000;
    margin-top: 2%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    background-image: url('./assets/ticker.jpg');
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
    border: 4px solid #a87026;
    box-shadow: 0 0 10px #d6a25e;
    padding: 15px;
    
  }

  .markBarCon {
    background-color: #222222a6;
    display: flex;
    flex-flow: row nowrap;
    width: 20%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding: 25px;
  }

  .markBar2 {
    flex-flow: column nowrap;
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: -10%;
  }

  .num {
    font-size: 30px;
    color: #ffffff;

    text-align: center;
  }

  .name {
    color: #ffffff;
    font-size: 35px;
    text-align: center;
    font-family: 'Russo One', sans-serif;
  }

  .num2 {
    font-size: 40px;
    color: white;
    margin-top: 15px;
    text-align: center;
  }

  .name2 {
    color: white;
    font-size: 38px;
    text-align: center;
    font-weight: 500;
  }

  .markBar img {
    width: 115px;
    height: 115px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 20%;
  }

  .markBarMain {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-top: 3%;
    margin-bottom: 5%;
  }

  .stakeMain {
    display: flex;
    flex-flow: column nowrap;
    background-color: black;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.49343487394957986) 0%, rgba(0, 0, 0, 0.804359243697479) 69%, rgba(0, 0, 0, 0.896796218487395) 98%), url('./assets/fire.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    
  }

  .stakeMain2 {
    border-right: 20px #f3ba2f solid;
    border-top: #f3ba2f 20px solid;
  }

  .sticky {
    width: 20%;

    bottom: 0;
    right: 90%;
    margin-top: -10%;
  }

  .items {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }

  .board {
    margin-bottom: 5%;
    padding: 35px;
    border: #ffffff 12px double;
    margin-left: 1%;
    margin-right: 1%;
    background: rgba(46, 46, 46, 0.2);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    width: 42%;
  }

  .boardMain {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
    width: 85%;
    
  }

  .nameStake {
    width: 50%;
  }

  .carrots {
    width: 90px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .egg {
    width: 70px;
    height: 70px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .egg2 {
    width: 95px;
    height: 95px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .boardImg {
    background-color: #ffb963;

  }

  .boardPics {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    margin-bottom: 8%;
  }

  .approveBtns {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    margin-top: 2%;
  }

  .approveBtns1 {
    border: 5px solid;
    border-image-slice: 1;
    border-image-source: linear-gradient(to left, #facc15, #f0d56b);
    background-color: rgba(206, 8, 8, 0);
    box-shadow: 0 0 8px gold;
    color: rgb(255, 255, 255);
    padding: 25px;
    font-size: 45px;
    font-family: 'Russo One', sans-serif;
    width: 700px;
    transition: transform .3s;
  }

  .approveBtns1:hover {
    transform: scale(1.1);
  }

  .approveBtns2:hover {
    transform: scale(1.1);
  }

  .approveBtns3:hover {
    transform: scale(1.1);
  }

  .approveBtns3 {
    border: 5px solid;
    border-image-slice: 1;
    border-image-source: linear-gradient(to left, rgb(231, 80, 80), rgb(206, 8, 8));
    background-color: rgba(206, 8, 8, 0);
    box-shadow: 0 0 8px red;
    color: white;
    color: white;
    padding: 25px;
    font-size: 45px;
    font-family: 'Russo One', sans-serif;
    width: 700px;
    transition: transform .3s;

  }

  .approveBtns3Dis {
    background-color: rgb(224, 111, 111);
    border: none;
    cursor: no-drop;
    color: rgb(187, 183, 183);

    padding: 20px;
    font-size: 23px;
    font-family: 'Russo One', sans-serif;
    width: 400px;
    transition: transform .3s;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  }

  .approveBtns2 {
    background: none;
    color: white;
    padding: 20px;
    border-radius: 10px;
    font-size: 23px;
    border: 4px #70512b solid;

    width: 200px;
    transition: transform .3s;
  }


  .wallet3 {
    
    background-color: black;
    border: solid 5px;
    color: rgb(255, 255, 255);
    padding: 15px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 45px;
    margin-left: auto;
    margin-right: auto;
    width: 480px;
    transition: transform .5s;
    z-index: 1;
    margin-bottom: 10%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;

  }

  .lookDown {
    width: 20px;
    height: 20px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }


  .wallet3:hover {
    cursor: pointer;
  }

  .wallet3::after {
    transition: all 0.8s
  }

  .btn3 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .link {
    text-align: center;
    font-size: 15px;
    margin-top: 5px;
  }

  .images_show {
    width: 90%;
  }

  .hide {
    display: none;
  }

  .stake {
    display: block;
    background-color: red;
    color: white;
    border-radius: 10px;
    border: none;
    width: fit-content;
    padding: 5px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    cursor: pointer;
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
  }

  .stake2 {
    display: block;
    background-color: red;
    color: white;
    border-radius: 10px;
    border: none;
    width: 85px;
    padding: 5px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    cursor: pointer;
    margin-top: 10px;
    text-align: center;
  }

  .stake3 {
    display: block;
    background-color: red;
    color: white;
    border-radius: 10px;
    border: none;
    width: 85px;
    padding: 5px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    cursor: pointer;
    margin-bottom: 10px;
    text-align: center;
  }

  .stake:hover {}

  .hide:hover {}

  .tokenIds {
    display: flex;
    justify-content: center;
    color: white;
    flex-flow: row wrap;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    height: max-content;
    margin-bottom: 10%;
  }

  .tokenIds2 {
    display: flex;
    justify-content: center;
    color: white;
    flex-flow: row wrap;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 15%;
  }

  .tS {
    color: #3a9ae8;
    text-align: center;
    margin-top: 2%;
    font-size: 40px;
    
  }

  .unstakeImg {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;

  }

  .imagesAndBtn {
    justify-content: center;
  }

  .flip-card {
    background-color: transparent;
    width: 150px;
    perspective: 1000px;
    background-color: #e0706c;
    height: fit-content;

    margin-top: 10%;
  }

  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }

  .flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
  }

  .flip-card-front,
  .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  .btnCenter {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .flip-card-front {
    /* background-color: #bbb;*/
    color: black;
  }

  .flip-card-back {
    /*background-color: #2980b9;*/
    color: white;
    transform: rotateY(180deg);
  }

  .loginFormNote {
    height: 90%;
    width: 90%;
    background-color: rgba(255, 255, 255, 0.13);
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    border-radius: 10px;
    backdrop-filter: blur(10px);
    border: 2px solid rgba(255, 255, 255, 0.1);
    box-shadow: 0 0 40px rgba(8, 7, 16, 0.6);
    padding: 50px 35px;
  }

  .formMain {z-index: 10000000000000000;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.856);
    position: fixed;
    width: 80%;
    height: 80vh;
    top: 50%;
    left: 50%;
    z-index: 1;
    overflow: scroll;
    overflow-x: hidden;
    border-radius: 10px;
     border: white 3px solid;

  }

  .formMain2 {
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.856);
    position: fixed;
    width: 50%;
    height: 80vh;
    top: 50%;
    left: 50%;
    z-index: 1;
    overflow: scroll;
    overflow-x: hidden;
    border-radius: 10px;
    z-index: 100000;
    border: white 3px solid;
    padding: 10px;
  }

  .formMain1 {z-index: 10000000000000000;
    transform: translate(-50%, -50%);
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 50%;
    left: 50%;
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
  }

  .formMain1-1 {
    transform: translate(-50%, -50%);
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 50%;
    left: 50%;
    z-index: 100000;
    background-color: #00000062;
  }

  .ownNFT {
    margin-top: 8%;
    margin-bottom: 5.5%;
  }

  .elementName {
    font-weight: 500;
    color: red;
    font-size: 20px;
    text-align: center;
  }

  .elementName2 {
    font-weight: 500;
    color: rgb(255, 255, 255);
    font-size: 20px;
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .elementName3 {
    font-weight: 500;
    color: red;
    font-size: 20px;
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .medalDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .closeNote {
    position: absolute;
    right: 0;
    top: 1%;
    color: rgba(255, 255, 255, 0.836);
    font-size: 30px;

    background-color: rgba(172, 67, 67, 0);
    width: 100px;
    height: fit-content;
    cursor: pointer;
    border: none;
  }

  .closeNote:hover,
  .closeNote:focus {
    color: rgb(71, 70, 70);
  }

  .rewards {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;

  }

  .discordB {
    display: none;
  }

  .medal {
    width: 35px;
    height: 35px;
  }

  .stakebtn:hover {
    transform: scale(1.1);
  }

  .stakebtn2:hover {
    transform: scale(1.1);
  }

  .stakebtnDiv {
    
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .rabbitImg {
    width: 200px;
    margin-top: 20%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 2%;
  }

  .rabbitImgLogo {
    width: 200px;
    margin-top: 20%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    border-radius: 100px;
    margin-bottom: 2%;
    border: 4px solid goldenrod;
    box-shadow: 0 0 10px gold;
  }

  .rabbitImg2 {
    width: 200px;
    margin-top: 20%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .stakeT {
    color: #3a9ae8;
    font-size: 90px;
  }
}

@media screen and (min-width: 3840px) {

  .btns {
    
    width: 150px;
    background-color: #facc15;
    color: rgb(0, 0, 0);
    padding: 5px;
    font-weight: 500;
    margin-bottom: 5%;
    transition: transform .3s;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;

  }

  .btnsDis {
    width: 150px;
    background-color: #77946c;
    border: none;
    color: rgb(255, 255, 255);
    padding: 5px;
    font-weight: 500;
    margin-bottom: 5%;
    transition: transform .3s;
    cursor: no-drop;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  }

  .btns2 {
    width: 150px;
    color: rgb(0, 0, 0);
    padding: 5px;
    font-weight: 500;
    transition: transform .3s;
    background-color: rgb(238, 62, 62);
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    border: none;
  }

  .btns2Dis {
    width: 150px;
    background-color: rgb(196, 134, 134);
    cursor: no-drop;
    color: rgb(255, 255, 255);
    padding: 5px;
    font-weight: 500;
    transition: transform .3s;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  }

  .btns:hover {
    transform: scale(1.1);
  }

  .btns2:hover {
    transform: scale(1.1);
  }

  .btnsMain {
    display: flex;
    flex-flow: column nowrap;
    margin-top: auto;
    margin-bottom: auto;
    display: none;
  }

  .btnsMainBig {
    display: flex;
    flex-flow: column nowrap;
    margin-top: auto;
    margin-bottom: auto;
  }

  .markBar {
    background-color: #000000;
    margin-top: 2%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    background-image: url('./assets/ticker.jpg');
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
    border: 4px solid #a87026;
    box-shadow: 0 0 10px #d6a25e;
    padding: 15px;
    
  }

  .markBarCon {
    background-color: #222222a6;
    display: flex;
    flex-flow: row nowrap;
    width: 20%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding: 25px;
  }

  .markBar2 {
    flex-flow: column nowrap;
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: -10%;
  }

  .num {
    font-size: 40px;
    color: #ffffff;

    text-align: center;
  }

  .name {
    color: #ffffff;
    font-size: 45px;
    text-align: center;
    font-family: 'Russo One', sans-serif;
  }

  .num2 {
    font-size: 57px;
    color: white;
    margin-top: 15px;
    text-align: center;
  }

  .name2 {
    color: white;
    font-size: 55px;
    text-align: center;
    font-weight: 500;
  }

  .markBar img {
    width: 165px;
    height: 165px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-right: 20%;
  }

  .markBarMain {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-top: 3%;
    margin-bottom: 5%;
  }

  .stakeMain {
    display: flex;
    flex-flow: column nowrap;
    background-color: black;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    height: max-content;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.49343487394957986) 0%, rgba(0, 0, 0, 0.804359243697479) 69%, rgba(0, 0, 0, 0.896796218487395) 98%), url('./assets/fire.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    
  }

  .stakeMain2 {
    border-right: 20px #f3ba2f solid;
    border-top: #f3ba2f 20px solid;
  }

  .sticky {
    width: 20%;

    bottom: 0;
    right: 90%;
    margin-top: -10%;
  }

  .items {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }

  .board {
    margin-bottom: 5%;
    padding: 35px;
    border: #ffffff 12px double;
    margin-left: 1%;
    margin-right: 1%;
    background: rgba(46, 46, 46, 0.2);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    width: 42%;
  }

  .boardMain {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
    width: 85%;
    
  }

  .nameStake {
    width: 50%;
  }

  .carrots {
    width: 90px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .egg {
    width: 70px;
    height: 70px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .egg2 {
    width: 95px;
    height: 95px;
    margin-top: auto;
    margin-bottom: auto;
  }

  .boardImg {
    background-color: #ffb963;

  }

  .boardPics {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    margin-bottom: 8%;
  }

  .approveBtns {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    margin-top: 2%;
  }

  .approveBtns1 {
    border: 8px solid;
    border-image-slice: 1;
    border-image-source: linear-gradient(to left, #facc15, #f0d56b);
    background-color: rgba(206, 8, 8, 0);
    box-shadow: 0 0 8px gold;
    color: rgb(255, 255, 255);
    padding: 25px;
    font-size: 65px;
    font-family: 'Russo One', sans-serif;
    width: 900px;
    transition: transform .3s;
  }

  .approveBtns1:hover {
    transform: scale(1.1);
  }

  .approveBtns2:hover {
    transform: scale(1.1);
  }

  .approveBtns3:hover {
    transform: scale(1.1);
  }

  .approveBtns3 {
    border: 8px solid;
    border-image-slice: 1;
    border-image-source: linear-gradient(to left, rgb(231, 80, 80), rgb(206, 8, 8));
    background-color: rgba(206, 8, 8, 0);
    box-shadow: 0 0 8px red;
    color: white;
    color: white;
    padding: 25px;
    font-size: 65px;
    font-family: 'Russo One', sans-serif;
    width: 900px;
    transition: transform .3s;

  }

  .approveBtns3Dis {
    background-color: rgb(224, 111, 111);
    border: none;
    cursor: no-drop;
    color: rgb(187, 183, 183);

    padding: 20px;
    font-size: 23px;
    font-family: 'Russo One', sans-serif;
    width: 400px;
    transition: transform .3s;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  }

  .approveBtns2 {
    background: none;
    color: white;
    padding: 20px;
    border-radius: 10px;
    font-size: 23px;
    border: 4px #70512b solid;

    width: 200px;
    transition: transform .3s;
  }


  .wallet3 {
    
    background-color: black;
    border: solid 7px;
    color: rgb(255, 255, 255);
    padding: 15px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 60px;
    margin-left: auto;
    margin-right: auto;
    width: 600px;
    transition: transform .5s;
    z-index: 1;
    margin-bottom: 10%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;

  }

  .lookDown {
    width: 20px;
    height: 20px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }


  .wallet3:hover {
    cursor: pointer;
  }

  .wallet3::after {
    transition: all 0.8s
  }

  .btn3 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .link {
    text-align: center;
    font-size: 15px;
    margin-top: 5px;
  }

  .images_show {
    width: 90%;
  }

  .hide {
    display: none;
  }

  .stake {
    display: block;
    background-color: red;
    color: white;
    border-radius: 10px;
    border: none;
    width: fit-content;
    padding: 5px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    cursor: pointer;
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
  }

  .stake2 {
    display: block;
    background-color: red;
    color: white;
    border-radius: 10px;
    border: none;
    width: 85px;
    padding: 5px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    cursor: pointer;
    margin-top: 10px;
    text-align: center;
  }

  .stake3 {
    display: block;
    background-color: red;
    color: white;
    border-radius: 10px;
    border: none;
    width: 85px;
    padding: 5px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    cursor: pointer;
    margin-bottom: 10px;
    text-align: center;
  }

  .stake:hover {}

  .hide:hover {}

  .tokenIds {
    display: flex;
    justify-content: center;
    color: white;
    flex-flow: row wrap;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    height: max-content;
    margin-bottom: 10%;
  }

  .tokenIds2 {
    display: flex;
    justify-content: center;
    color: white;
    flex-flow: row wrap;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 15%;
  }

  .tS {
    color: #3a9ae8;
    text-align: center;
    margin-top: 2%;
    font-size: 40px;
    
  }

  .unstakeImg {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;

  }

  .imagesAndBtn {
    justify-content: center;
  }

  .flip-card {
    background-color: transparent;
    width: 150px;
    perspective: 1000px;
    background-color: #e0706c;
    height: fit-content;

    margin-top: 10%;
  }

  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }

  .flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
  }

  .flip-card-front,
  .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  .btnCenter {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .flip-card-front {
    /* background-color: #bbb;*/
    color: black;
  }

  .flip-card-back {
    /*background-color: #2980b9;*/
    color: white;
    transform: rotateY(180deg);
  }

  .loginFormNote {
    height: 90%;
    width: 90%;
    background-color: rgba(255, 255, 255, 0.13);
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    border-radius: 10px;
    backdrop-filter: blur(10px);
    border: 2px solid rgba(255, 255, 255, 0.1);
    box-shadow: 0 0 40px rgba(8, 7, 16, 0.6);
    padding: 50px 35px;
  }

  .formMain {z-index: 10000000000000000;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.856);
    position: fixed;
    width: 80%;
    height: 80vh;
    top: 50%;
    left: 50%;
    z-index: 1;
    overflow: scroll;
    overflow-x: hidden;
    border-radius: 10px;
     border: white 3px solid;

  }

  .formMain2 {
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.856);
    position: fixed;
    width: 50%;
    height: 80vh;
    top: 50%;
    left: 50%;
    z-index: 1;
    overflow: scroll;
    overflow-x: hidden;
    border-radius: 10px;
    z-index: 100000;
    border: white 3px solid;
    padding: 10px;
  }

  .formMain1 {z-index: 10000000000000000;
    transform: translate(-50%, -50%);
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 50%;
    left: 50%;
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
  }

  .formMain1-1 {
    transform: translate(-50%, -50%);
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 50%;
    left: 50%;
    z-index: 100000;
    background-color: #00000062;
  }

  .ownNFT {
    margin-top: 8%;
    margin-bottom: 5.5%;
  }

  .elementName {
    font-weight: 500;
    color: red;
    font-size: 20px;
    text-align: center;
  }

  .elementName2 {
    font-weight: 500;
    color: rgb(255, 255, 255);
    font-size: 20px;
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .elementName3 {
    font-weight: 500;
    color: red;
    font-size: 20px;
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .medalDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }

  .closeNote {
    position: absolute;
    right: 0;
    top: 1%;
    color: rgba(255, 255, 255, 0.836);
    font-size: 30px;

    background-color: rgba(172, 67, 67, 0);
    width: 100px;
    height: fit-content;
    cursor: pointer;
    border: none;
  }

  .closeNote:hover,
  .closeNote:focus {
    color: rgb(71, 70, 70);
  }

  .rewards {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;

  }

  .discordB {
    display: none;
  }

  .medal {
    width: 35px;
    height: 35px;
  }

  .stakebtn:hover {
    transform: scale(1.1);
  }

  .stakebtn2:hover {
    transform: scale(1.1);
  }

  .stakebtnDiv {
    
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .rabbitImg {
    width: 300px;
    margin-top: 20%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 2%;
  }

  .rabbitImgLogo {
    width: 300px;
    margin-top: 20%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    border-radius: 100%;
    margin-bottom: 2%;
    border: 4px solid goldenrod;
    box-shadow: 0 0 10px gold;
  }

  .rabbitImg2 {
    width: 300px;
    margin-top: 20%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .stakeT {
    color: #3a9ae8;
    font-size: 120px;
  }
}